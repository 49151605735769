import React, { useEffect, useState } from "react";
import { createMessage } from "@appsmith/constants/messages";
import { Flex, Text } from "design-system";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Header from "IDE/Structure/Header";
import { MODULES, NEW_PACKAGE } from "../../messages";
import { getCurrentPackage } from "spread/packages/selectors";
import { useSelector } from "react-redux";
import PackageActionsDropDown from "./packageActionsDropdown/PackageActionsDropdown";
import { EditInteractionKind, SavingState } from "design-system-old";
import {
  getIsUpdatingCurrentPackage,
  getIsErrorUpdatePackage,
} from "spread/packages/selectors";
import PackagePublish from "./PackagePublish";
import { fetchPackageAction } from "spread/store/slices/packages";

const PackagePageHeader = () => {
  const dispatch = useDispatch();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const { packageId } = useParams<{ packageId: string }>();

  const isUpdatingCurrentPackage = useSelector(getIsUpdatingCurrentPackage);
  const currentPackage = useSelector(getCurrentPackage);
  const isErrorUpdatingPackage = useSelector(getIsErrorUpdatePackage);

  useEffect(() => {
    if (packageId) {
      dispatch(fetchPackageAction({ packageId }));
    }
  }, [packageId]);

  return (
    <Header>
      <Header.Left>{createMessage(MODULES)}</Header.Left>
      <Header.Center>
        {currentPackage && (
          <Flex alignItems={"center"}>
            {currentPackage.workspaceName && (
              <Text
                color={"var(--ads-v2-colors-content-label-inactive-fg)"}
                kind="body-m"
              >
                {currentPackage.workspaceName + " / "}
              </Text>
            )}
            <PackageActionsDropDown
              className="t--application-name editable-application-name max-w-48"
              currentPackage={currentPackage}
              defaultSavingState={
                isUpdatingCurrentPackage
                  ? SavingState.STARTED
                  : SavingState.NOT_STARTED
              }
              defaultValue={currentPackage?.name || ""}
              editInteractionKind={EditInteractionKind.SINGLE}
              editorName={createMessage(NEW_PACKAGE)}
              fill
              isError={isErrorUpdatingPackage}
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          </Flex>
        )}
      </Header.Center>
      <Header.Right>
        <PackagePublish />
      </Header.Right>
    </Header>
  );
};

export default PackagePageHeader;
