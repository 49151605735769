import {
  JsFileIconV2,
  jsFunctionIcon,
} from "pages/Editor/Explorer/ExplorerIcons";
import type { SearchItem } from "components/editorComponents/GlobalSearch/utils";

import { isJsFunction } from "./utils";

export const getJsSearchItemIcon = (item: SearchItem) => {
  if (isJsFunction(item)) {
    return jsFunctionIcon;
  }

  return JsFileIconV2();
};
