import { type GraphQLSchema, getIntrospectionQuery } from "graphql";
import { buildClientSchema, type IntrospectionQuery } from "graphql";

import { callEin } from "./ein";

async function fetchEinSchema() {
  try {
    const introspectionQuery = getIntrospectionQuery({
      descriptions: true,
      specifiedByUrl: true,
      directiveIsRepeatable: true,
      schemaDescription: true,
      inputValueDeprecation: true,
    });

    const introspectionQueryResponse =
      await callEin<IntrospectionQuery>(introspectionQuery);

    const schema = buildClientSchema(introspectionQueryResponse);

    return schema;
  } catch (error) {
    throw new Error("Failed to fetch EIN schema");
  }
}

declare global {
  interface Window {
    einSchema: GraphQLSchema | undefined;
  }
}

export function prefetchEinSchema(attempts = 3) {
  fetchEinSchema()
    .then((schema) => {
      window.einSchema = schema;
    })
    .catch(() => {
      if (attempts > 0) {
        setTimeout(() => prefetchEinSchema(attempts - 1), 1000);
      } else {
        window.einSchema = undefined;
      }
    });
}

export const getEinSchema = () => {
  return window.einSchema;
};
