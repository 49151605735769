export const SNAPSHOT_EXPIRY_IN_DAYS = 5;

export const defaultAutoLayoutWidgets = [
  // #region @spread
  "STEPPER_WIDGET",
  // #endregion

  "CONTAINER_WIDGET",
  "TABS_WIDGET",
  "LIST_WIDGET_V2",
  "MODAL_WIDGET",
  "STATBOX_WIDGET",
  "FORM_WIDGET",
];
