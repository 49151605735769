import type { components } from "@spread-ai/config-service";

import { API_PATH } from "./urls";

const SYSTEM_CONFIG_ENDPOINT = `${API_PATH}/config/system-config`;

type SystemConfig = components["schemas"]["SystemConfig"];

const DEFAULT_SYSTEM_CONFIG: SystemConfig = {
  heapTrackingCode: 0,
  hotjarTrackingCode: 0,
  unleash: {
    enabled: false,
  },
  deployedApps: [],
  host: "",
  apmServerUrl: "",
  environmentName: "",
  goJsLicenseKey: "",
  rendererServerUrl: "",
  mode: "prod",
  contentLanguages: [],
  isSso: false,
};

export const getSystemConfig = async () => {
  try {
    const response = await fetch(SYSTEM_CONFIG_ENDPOINT, {
      method: "GET",
      credentials: "include",
      mode: "cors",
    });
    const data: SystemConfig = await response.json();
    return data;
  } catch (error) {
    return DEFAULT_SYSTEM_CONFIG;
  }
};
