import { isPermitted } from "@appsmith/utils/permissionHelpers";
// #region @spread
export enum PACKAGE_USER_PERMISSIONS_TYPE {
  MANAGE_PACKAGES = "manage:packages",
  EXPORT_PACKAGES = "export:packages",
  DELETE_PACKAGES = "delete:packages",
  READ_PACKAGE_MODULE_INSTANCES = "read:packageModuleInstances",
  READ_PACKAGES = "read:packages",
  PUBLISH_PACKAGES = "publish:packages",
}
// #endregion @spread

export const hasEditPackagePermission = (permissions: string[] = []) => {
  return isPermitted(
    permissions,
    PACKAGE_USER_PERMISSIONS_TYPE.MANAGE_PACKAGES,
  );
};
