import type { AppState } from "@appsmith/reducers";
import type { SpreadPackage } from "./types";

export const getIsFetchingPackages = (state: AppState) =>
  state.spread.packages.loadingStates.isFetchingPackages || false;

export const getCurrentWorkspacePackagesList = (
  state: AppState,
): SpreadPackage[] =>
  (Object.values(state.spread.packages.packages) as SpreadPackage[]) || [];

export const getCreatePackageErrorMessage = (state: AppState) =>
  state.spread.packages.errors.createPackageError;

export const getPackagesOfWorkspaceErrorMessage = (state: AppState) =>
  state.spread.packages.errors.getPackagesOfWorkspaceError;

export const getDeletePackageErrorMessage = (state: AppState) =>
  state.spread.packages.errors.deletePackageError;

export const getIsUpdatingPackage = (state: AppState) =>
  state.spread.packages.loadingStates.isUpdatingPackage || false;

export const getUpdatePackageError = (state: AppState) =>
  state.spread.packages.errors.updatePackageError || false;

export const getCurrentPackage = (state: AppState): SpreadPackage | null =>
  state.spread.packages.currentPackage || null;

export const getIsUpdatingCurrentPackage = (state: AppState) =>
  state.spread.packages.loadingStates.isUpdatingCurrentPackage || false;

export const getIsErrorUpdatePackage = (state: AppState) =>
  Boolean(getUpdatePackageError(state));
