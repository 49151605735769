import { tailwindLayers } from "constants/Layers";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import useHorizontalResize from "utils/hooks/useHorizontalResize";

const DEFAULT_WIDTH = 300;

const ResizeableDiv = styled.div`
  display: flex;
  height: 100%;
  flex-shrink: 0;
`;

const ResizerHandler = styled.div<{ resizing: boolean }>`
  width: 6px;
  height: 100%;
  margin-left: 2px;
  border-right: 1px solid var(--ads-v2-color-border);
  background: ${(props) =>
    props.resizing ? "var(--ads-v2-color-border)" : "transparent"};
  &:hover {
    background: var(--ads-v2-color-border);
    border-color: transparent;
  }
`;

export const ResizeablePanel: React.FC<{
  resizeableSide: "left" | "right";
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({ children, className, resizeableSide, style }) => {
  const sizeableRef = useRef<HTMLDivElement>(null);
  const [variableEditorWidth, setVariableEditorWidth] =
    React.useState(DEFAULT_WIDTH);

  const onVariableEditorWidthChange = useCallback((newWidth) => {
    setVariableEditorWidth(newWidth);
  }, []);

  const { onMouseDown, onMouseUp, onTouchStart, resizing } =
    useHorizontalResize(
      sizeableRef,
      onVariableEditorWidthChange,
      undefined,
      resizeableSide === "left",
    );

  const resizableDiv = (
    <ResizeableDiv
      className={className}
      ref={sizeableRef}
      style={{
        ...style,
        width: `${variableEditorWidth}px`,
      }}
    >
      {children}
    </ResizeableDiv>
  );

  const resizeHandler = (
    <div
      className={`w-2 h-full -ml-2 group  cursor-ew-resize ${tailwindLayers.resizer}`}
      onMouseDown={onMouseDown}
      onTouchEnd={onMouseUp}
      onTouchStart={onTouchStart}
    >
      <ResizerHandler className="transform transition" resizing={resizing} />
    </div>
  );

  if (resizeableSide === "left") {
    return (
      <>
        {resizeHandler}
        {resizableDiv}
      </>
    );
  } else {
    return (
      <>
        {resizableDiv}
        {resizeHandler}
      </>
    );
  }
};
