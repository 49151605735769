import { useSelector } from "react-redux";

import {
  getJSCollections,
  getPageList,
} from "@appsmith/selectors/entitiesSelector";
import { useMemo } from "react";
import { keyBy } from "lodash";
import type {
  JSCollectionData,
  JSCollectionDataState,
} from "@appsmith/reducers/entityReducers/jsActionsReducer";
import type { Page } from "@appsmith/constants/ReduxActionConstants";
import { isMatching } from "components/editorComponents/GlobalSearch/utils";

import type { JsActionData } from "./types";

/**
 * This hook is replacement for `useFilteredJSCollections` hook.
 * It includes both JSCollections and JSFunctions in the search results.
 */
export const useFilteredJSItems = (query: string) => {
  const jsActions = useSelector(getJSCollections);
  const pages = useSelector(getPageList) || [];
  const pageMap = keyBy(pages, "pageId");

  const items = buildItems(jsActions, pageMap, query);

  return useMemo(() => items, [query, jsActions, pageMap]);
};

/**
 * Function to build search items from JSCollections and JSFunctions
 */
const buildItems = (
  jsActions: JSCollectionDataState,
  pageMap: Record<string, Page>,
  query: string,
) => {
  return jsActions.reduce<(JSCollectionData | JsActionData)[]>(
    (acc, action) => {
      const page = pageMap[action?.config?.pageId];
      const isPageNameMatching = isMatching(page?.pageName, query);
      const isActionNameMatching = isMatching(action?.config?.name, query);

      if (isActionNameMatching || isPageNameMatching) {
        acc.push(action);
      }

      const functions = buildFunctions(action);
      acc.push(
        ...functions.filter((func) => isMatching(func.config.name, query)),
      );

      return acc;
    },
    [],
  );
};

/**
 * Function to extract JSFunctions from JSCollections and build search items
 */
const buildFunctions = (jsAction: JSCollectionData): JsActionData[] => {
  return jsAction.config.actions.map((jsFunc) => {
    const jsArgs = jsFunc.actionConfiguration.jsArguments || [];
    const jsArgsKeys = jsArgs.map((arg) => arg.name);
    const args = jsArgsKeys.join(", ");
    const name = `${jsFunc.fullyQualifiedName}(${args})`;
    const functionName = jsFunc.name;
    const collectionId = jsAction.config.id;

    return {
      isLoading: false,
      config: {
        ...jsFunc,
        name,
        functionName,
        collectionId,
      },
    };
  });
};
