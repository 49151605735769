export const ERROR_MESSAGE_CREATE_PACKAGE = () =>
  `We could not create the Package`;

export const ERROR_MESSAGE_UPDATE_PACKAGE = () =>
  `We could not update the Package`;

export const ERROR_MESSAGE_DELETE_PACKAGE = () =>
  `We could not delete the Package`;
export const ERROR_MESSAGE_GET_PACKAGES_OF_WORKSPACE = () =>
  `We could not fetch the Packages`;

export const ERROR_MESSAGE_FETCH_PACKAGE = () =>
  `We could not fetch the Package`;

export const PACKAGES_LIST_EMPTY_STATE = () =>
  `There are no packages in this workspace.`;

export const SPREAD_PACKAGES = () => `Packages`;

export const NEW_PACKAGE = () => "Package";
export const ARE_YOU_SURE = () => "Are you sure?";
export const DELETE_PACKAGE_LABEL = () => "Delete package";
export const DELETE_PACKAGE_ERROR = () => "Error while deleting package";

export const EDIT_PACKAGE_LABEL = () => "Edit package";

export const EDIT_TEXT_INPUT = () => "Edit text input";

export const NAME_CAN_NOT_BE_EMPTY = () => "Name can't be empty";
export const MODULES = () => "Modules";

export const PUBLISH_PACKAGE_LABEL = () => "Publish";
export const PUBLISH_PACKAGE_IN_LAUNCHER = () =>
  "Publish the current version of the package in the launcher";

export const BACK_TO_ALL_PACKAGES = () => "Back to all packages";
export const RENAME_PACKAGE_LABEL = () => "Rename";
export const EXPORT_PACKAGE_LABEL = () => "Export package";

export const EMPTY_EDITOR_NAME_ERROR = (editorName: string) =>
  `${editorName} name can't be empty`;

export const PACKAGE_MODULES_EMPTY_STATE = () =>
  "No modules in this package yet.";
export const PACKAGE_REUSABILITY_LEARN_MORE = () =>
  "Learn more about Studio Reusability.";
export const NEW_MODULE = () => "New Module";
