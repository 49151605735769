import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "selectors/usersSelectors";
import { callEin, gql } from "./ein";
import { initSpreadServices, updateLoggedUserInfo } from "./initSpreadServices";
import type { SpreadUser } from "./types";

interface WhoAmIResponse {
  whoami: Omit<SpreadUser, "language"> & { language: "english" | "german" };
}

const SPREAD_LANGUAGE_MAP = {
  english: "en",
  german: "de",
} as const;

/**
 * Starts the Unleash service with the current user info as context.
 */
export const SpreadProvider: React.FC = ({ children }) => {
  const appsmithUser = useSelector(getCurrentUser);

  useEffect(() => {
    initSpreadServices();
  }, []);

  useEffect(() => {
    if (!!appsmithUser?.email) {
      callEin<WhoAmIResponse>(gql`
        query WhoAmI {
          whoami {
            id
            name
            email
            language
          }
        }
      `).then(({ whoami: spreadUser }) => {
        updateLoggedUserInfo({
          ...spreadUser,
          language:
            SPREAD_LANGUAGE_MAP[spreadUser.language] ?? spreadUser.language,
        });
      });
    }
  }, [appsmithUser?.email]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
