import {
  parse,
  visit,
  type OperationDefinitionNode,
  type DocumentNode,
} from "graphql";

/**
 * Extracts the variables from a GraphQL query.
 * If the query is invalid - throws an error.
 *
 * @param query raw GraphQL query
 * @returns list of variables
 */
export const extractVariablesFromGraphQLQuery = (query: string): string[] => {
  let ast: DocumentNode;
  try {
    ast = parse(query);
  } catch {
    throw new Error("Invalid GraphQL query");
  }

  const variables = new Set<string>();

  visit(ast, {
    OperationDefinition(node: OperationDefinitionNode) {
      node.variableDefinitions?.forEach((varDef) => {
        variables.add(varDef.variable.name.value);
      });
    },
  });

  return Array.from(variables);
};
