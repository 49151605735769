import React from "react";
import styled from "styled-components";
import { Text, TextType } from "design-system-old";
import GenerateVariablesButton from "./GenerateVariablesButton";

const VariableHeader = styled.div`
  display: flex;
  width: 100%;
  background: var(--ads-v2-color-bg-subtle);
  padding: 3px 16px;
`;

const VariableHeaderText = styled(Text)`
  flex: 1;
  align-self: center;
`;

export const GraphQLVariablesHeader = () => {
  return (
    <VariableHeader>
      <VariableHeaderText color={"var(--ads-v2-color-fg)"} type={TextType.H6}>
        Query variables
      </VariableHeaderText>
      <GenerateVariablesButton />
    </VariableHeader>
  );
};
