import { UnleashClient } from "unleash-proxy-client";

import { UNLEASH_ENDPOINT } from "./urls";

interface FeatureFlagsServiceConfig {
  apiKey: string;
}

let unleash: UnleashClient | undefined;

export const initFeatureFlagsService = ({
  apiKey,
}: FeatureFlagsServiceConfig) => {
  unleash = new UnleashClient({
    url: UNLEASH_ENDPOINT,
    clientKey: apiKey,
    appName: "studio",
    environment: "production",
  });
  void unleash.start();
};

export const isFeatureEnabled = (flag: string) => {
  if (!unleash) {
    return false;
  }
  return unleash.isEnabled(flag);
};

export async function getConfigValueFromFlag(
  flag: string,
  variantName: string,
): Promise<string | undefined> {
  if (!unleash) {
    return undefined;
  }

  const getConfigFromUnleash = () => {
    const variant = unleash!.getVariant(flag);
    return variant.name === variantName ? variant.payload?.value : undefined;
  };

  const value = getConfigFromUnleash();
  if (value) {
    return value;
  }

  // If the value is not available yet, wait for an update event.
  // Maybe we are trying to get it too early.
  return new Promise((resolve) => {
    function onUnleashUpdate() {
      const value = getConfigFromUnleash();
      if (value) {
        unleash!.off("update", onUnleashUpdate);
        resolve(value);
      }
    }
    unleash!.on("update", onUnleashUpdate);

    // do not wait for more than 5 seconds
    setTimeout(() => {
      unleash!.off("update", onUnleashUpdate);
      resolve(undefined);
    }, 5000);
  });
}

export const updateUserContext = (context: { userEmail: string }) => {
  if (!unleash) {
    return;
  }

  void unleash.updateContext({
    properties: {
      userEmail: context.userEmail,
    },
  });
};
