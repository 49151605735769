// using `self` as this code may run on both the main thread and web workers
const PROTOCOL = self.location.protocol;

const HOSTNAME = self.location.hostname.replace("studio.", "");

/**
 * The main host for the SPREAD deployment.
 *
 * For example. for the local deployment, the base domain is
 * "local.spread.ai" and its services are deployed either in subdomains
 * (eg. "studio.local.spread.ai", "unleash.local.spread.ai") or in subpaths (eg. "/ein").
 *
 * @example
 * // Local:
 * "https://local.spread.ai"
 * // Dev:
 * "https://dev.stage.spread.ai"
 * // Test:
 * "https://test.stage.spread.ai"
 * // etc.
 */
const HOST = `${PROTOCOL}//${HOSTNAME}`;
export const LAUNCHER_PATH = `${HOST}/`;
export const API_PATH = `${HOST}/api/v1`;
export const EIN_PATH = `${HOST}/ein`;

const UNLEASH_HOST = `${PROTOCOL}//unleash.${HOSTNAME}`;
export const UNLEASH_ENDPOINT = `${UNLEASH_HOST}/api/frontend`;
export const EIN_FEDERATOR_PATH = "http://federator-app:8080/ein";

export function navigateToSpreadDashboard(path = "/") {
  window.location.assign(HOST + path);
}
