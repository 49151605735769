import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Menu, toast, MenuTrigger } from "design-system";
import type {
  CommonComponentProps,
  EditInteractionKind,
} from "design-system-old";
import { Container, StyledIcon } from "pages/Editor/EditorName/components";
import EditableName from "./EditableName";
import { SavingState } from "design-system-old";

import type { noop } from "lodash";
import { NavigationMenu } from "pages/Editor/EditorName/NavigationMenu";
import type { SpreadPackage } from "spread/packages/types";
import { updatePackageAction } from "spread/store/slices/packages";
import { createMessage } from "@appsmith/constants/messages";
import { EMPTY_EDITOR_NAME_ERROR } from "spread/packages/messages";
import { usePackageNavigationMenuItems } from "./usePackageNavigationMenuItems";

type PackageActionsDropDownProps = CommonComponentProps & {
  currentPackage: SpreadPackage;
  defaultValue: string;
  placeholder?: string;
  editInteractionKind: EditInteractionKind;
  defaultSavingState: SavingState;
  inputValidation?: (value: string) => string | boolean;
  fill?: boolean;
  isPopoverOpen: boolean;
  setIsPopoverOpen: typeof noop;
  editorName: string;
  isError?: boolean;
};

const PackageActionsDropDown = (props: PackageActionsDropDownProps) => {
  const {
    currentPackage,
    defaultSavingState,
    defaultValue,
    editorName,
    isError,
    isPopoverOpen,
    setIsPopoverOpen,
  } = props;

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [savingState, setSavingState] = useState<SavingState>(
    SavingState.NOT_STARTED,
  );
  const [isInvalid, setIsInvalid] = useState<string | boolean>(false);

  const inputValidation = (value: string) => {
    if (value.trim() === "") {
      toast.show(createMessage(EMPTY_EDITOR_NAME_ERROR, editorName), {
        kind: "error",
      });
    }
    return false;
  };

  const enableEditMode = useCallback(
    (e: React.MouseEvent) => {
      setIsEditing(true);
      const errorMessage = inputValidation(defaultValue);
      setIsInvalid(errorMessage ? errorMessage : false);
      e.preventDefault();
      e.stopPropagation();
    },
    [inputValidation, defaultValue],
  );

  const handleNameClick = useCallback(() => {
    if (!isEditing) {
      setIsPopoverOpen((isOpen: boolean) => {
        return !isOpen;
      });
    }
  }, [isEditing]);

  const handleOnInteraction = useCallback((nextOpenState: boolean) => {
    if (!nextOpenState) {
      setIsPopoverOpen(false);
    }
  }, []);

  const updateName = (name: string) => {
    dispatch(
      updatePackageAction({
        packageId: currentPackage.id,
        data: { ...currentPackage, name },
      }),
    );
  };

  const navigationMenuData = usePackageNavigationMenuItems({ enableEditMode });
  return (
    <Menu
      className="t--editor-menu"
      onOpenChange={handleOnInteraction}
      open={isPopoverOpen}
    >
      <MenuTrigger disabled={isEditing}>
        <Container onClick={handleNameClick}>
          <EditableName
            className={props.className}
            defaultSavingState={defaultSavingState}
            defaultValue={currentPackage?.name || ""}
            editInteractionKind={props.editInteractionKind}
            hideEditIcon
            inputValidation={inputValidation}
            isEditing={isEditing}
            isError={isError}
            isInvalid={isInvalid}
            onBlur={updateName}
            placeholder={props.placeholder}
            savingState={savingState}
            setIsEditing={setIsEditing}
            setIsInvalid={setIsInvalid}
            setSavingState={setSavingState}
          />
          {!isEditing && (
            <StyledIcon
              name={isPopoverOpen ? "expand-less" : "down-arrow"}
              size="md"
            />
          )}
        </Container>
      </MenuTrigger>
      <NavigationMenu
        menuItems={navigationMenuData}
        setIsPopoverOpen={setIsPopoverOpen}
      />
    </Menu>
  );
};

export default PackageActionsDropDown;
