import React, { useCallback } from "react";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { API_EDITOR_FORM_NAME } from "@appsmith/constants/forms";
import { Button } from "design-system";

import { generateVariablesValue } from "./generateVariablesValue";

const GenerateVariablesButton = (props: any) => {
  const onClick = useCallback(() => {
    const query = props.actionConfigurationBody;
    const variablesValue = generateVariablesValue(query);
    props.updateVariables(variablesValue);
  }, [props.actionConfigurationBody]);

  return (
    <Button
      className="t--add-item"
      kind={"secondary"}
      onClick={onClick}
      size={"sm"}
    >
      Generate
    </Button>
  );
};

const select = formValueSelector(API_EDITOR_FORM_NAME);

export default connect(
  // state to props
  (state) => ({
    actionConfigurationBody: select(state, "actionConfiguration.body") || "",
  }),
  // dispatch to props
  (dispatch: any) => ({
    updateVariables: (variablesValue: string) => {
      dispatch(
        change(
          API_EDITOR_FORM_NAME,
          "actionConfiguration.pluginSpecifiedTemplates[1].value",
          variablesValue,
        ),
      );
    },
  }),
)(GenerateVariablesButton);
