import { callEin } from "spread/ein";
import type { EnabledFlow } from "./types";

const spreadFlowsWindowProperty = Symbol("spreadFlows");
// TODO: @spread Add enabled flows to SPREAD store instead of a global window property
declare global {
  interface Window {
    [spreadFlowsWindowProperty]?: EnabledFlow[];
  }
}

export async function prefetchEnabledFlows(): Promise<void> {
  try {
    const response = await callEin<{
      flows: {
        items: EnabledFlow[];
      };
    }>(/* GraphQL */ `
      query AllEnabledFlows {
        flows(where: { enabled: true }) {
          items {
            id
            name
            parameters {
              name
            }
          }
        }
      }
    `);
    window[spreadFlowsWindowProperty] = response.flows?.items ?? [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("[SPREAD] Failed to fetch enabled flows", error);
  }
}

export function getEnabledFlows(): EnabledFlow[] {
  return window[spreadFlowsWindowProperty] ?? [];
}
