import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import type { IconNames } from "design-system";
import type { AppColorCode } from "../../../constants/DefaultTheme";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { validateResponse } from "../../../sagas/ErrorSagas";
import { getCurrentWorkspaceId } from "@appsmith/selectors/selectedWorkspaceSelectors";

import {
  createPackage,
  type CreatePackageRequest,
  type CreatePackageResponse,
  getAllPackagesOfWorkspace,
  type GetAllPackagesOfWorkspaceResponse,
  deletePackage,
  type DeletePackageResponse,
  updatePackage,
  type UpdatePackageRequest,
  fetchPackage,
  type FetchPackageResponse,
} from "../../packages/service";
import {
  createPackageErrorAction,
  createPackageAction,
  createPackageSuccessAction,
  deletePackageErrorAction,
  deletePackageAction,
  deletePackageSuccessAction,
  getPackagesOfWorkspaceErrorAction,
  getPackagesOfWorkspaceAction,
  getPackagesOfWorkspaceSuccessAction,
  updatePackageErrorAction,
  updatePackageAction,
  updatePackageSuccessAction,
  fetchPackageAction,
  fetchPackageSuccessAction,
  fetchPackageErrorAction,
} from "./packages";
import history from "utils/history";
import { createPackageURL } from "../../packages/packageRoutes";

function* createPackageSaga(
  action: ReduxAction<{
    name: string;
    icon: IconNames;
    color: AppColorCode;
    workspaceId: string;
  }>,
) {
  const { color, icon, name, workspaceId } = action.payload;
  try {
    const request: CreatePackageRequest = {
      name,
      icon,
      color,
      workspaceId,
    };

    const response: CreatePackageResponse = yield call(createPackage, request);
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const newPackage = response.data;
      yield put(createPackageSuccessAction(response.data));
      const packageId = newPackage.id;
      history.push(createPackageURL({ packageId }));
    }
  } catch (error) {
    yield put(createPackageErrorAction());
  }
}

function* getPackagesOfWorkspaceSaga(action?: ReduxAction<string>) {
  let activeWorkspaceId: string = "";
  if (!action?.payload) {
    activeWorkspaceId = yield select(getCurrentWorkspaceId);
  } else {
    activeWorkspaceId = action.payload;
  }

  try {
    const response: GetAllPackagesOfWorkspaceResponse = yield call(
      getAllPackagesOfWorkspace,
      activeWorkspaceId,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      const spreadPackages = response.data;

      yield put(getPackagesOfWorkspaceSuccessAction(spreadPackages));
    }
  } catch (error) {
    yield put(getPackagesOfWorkspaceErrorAction());
  }
}

function* deletePackageSaga(action: ReduxAction<{ packageId: string }>) {
  const { packageId } = action.payload;
  try {
    const response: DeletePackageResponse = yield call(
      deletePackage,
      packageId,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(deletePackageSuccessAction(packageId));
    }
  } catch (error) {
    yield put(deletePackageErrorAction());
  }
}

function* updatePackageSaga(action: ReduxAction<UpdatePackageRequest>) {
  const { data, packageId } = action.payload;

  try {
    const request: UpdatePackageRequest = {
      data: {
        name: data.name,
        icon: data.icon,
        color: data.color,
      },
      packageId,
    };

    const response: CreatePackageResponse = yield call(updatePackage, request);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(updatePackageSuccessAction(response.data));
    }
  } catch (error) {
    yield put(updatePackageErrorAction());
  }
}

function* fetchPackageSaga(action: ReduxAction<{ packageId: string }>) {
  const { packageId } = action.payload;
  try {
    const response: FetchPackageResponse = yield call(fetchPackage, packageId);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(fetchPackageSuccessAction(response.data));
    }
  } catch (error) {
    yield put(fetchPackageErrorAction());
  }
}

export function* packageSagas() {
  yield all([
    takeLatest(createPackageAction.type, createPackageSaga),
    takeLatest(getPackagesOfWorkspaceAction.type, getPackagesOfWorkspaceSaga),
    takeLatest(deletePackageAction.type, deletePackageSaga),
    takeLatest(updatePackageAction.type, updatePackageSaga),
    takeLatest(fetchPackageAction.type, fetchPackageSaga),
  ]);
}
