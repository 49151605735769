import { CodeEditorColors } from "components/editorComponents/CodeEditor/constants";
import { Checkbox, Icon } from "design-system";
import { Text, TextType } from "design-system-old";
import GraphiQLExplorer from "graphiql-explorer";
import React from "react";
import { getEinSchema } from "spread/einSchema";
import styled, { css } from "styled-components";

const COLORS_MAP = {
  FIELD: CodeEditorColors.KEYWORD,
  ARGUMENT: CodeEditorColors.FUNCTION_ARGS,
  SELECTED_ITEM: CodeEditorColors.PROPERTY,
} as const;

const graphiqlExplorerStyles = css`
  .docExplorerWrap {
    height: unset !important;
    min-width: unset !important;
    width: unset !important;
    overflow: auto !important;
    z-index: 0 !important;

    .doc-explorer-title-bar {
      display: none;
    }

    .doc-explorer-contents {
      .graphiql-explorer-root {
        font-family: var(--ads-v2-font-family-code) !important;
        font-size: 13px !important;

        [id^="query-"] {
          /* these styles appear when there is more than one operation in the editor */
          border-bottom: none !important;
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;

          .graphiql-operation-title-bar {
            display: none;

            > span > input {
              /* width: 160px !important; */
              border: none !important;
              pointer-events: none;
            }

            /* Hide buttons to add new/duplicate query */
            .toolbar-button {
              display: none !important;
            }
          }
        }

        /* hide extra operations */
        [id^="query"]:not(:first-child) {
          display: none;
        }

        [data-field-name] > :is(span:has(.arrow-icon), label):nth-child(1),
        [data-field-name]
          > span:nth-child(1)
          > :is(span:has(.arrow-icon), label):nth-child(1),
        [data-arg-name] > :is(span:has(.arrow-icon), label):nth-child(1),
        [data-arg-name]
          > span:nth-child(1)
          > :is(span:has(.arrow-icon), label):nth-child(1) {
          margin-right: 4px;
        }

        [data-field-name] {
          /* field label */
          span:nth-child(2) {
            color: ${COLORS_MAP.FIELD} !important;
          }

          span:has(.arrow-icon[data-open="true"]) + span:nth-child(2),
          .ads-v2-checkbox[data-checked="true"] + span:nth-child(2) {
            color: ${COLORS_MAP.SELECTED_ITEM} !important;
          }
        }

        /* Type (for fragments) */
        div > div[class^="graphiql-explorer"] > span:nth-child(1) {
          display: flex;
          align-items: center;

          & > :is(label, span):nth-child(1) {
            margin-right: 4px;
          }

          span:has(.arrow-icon[data-open="true"]) + span:nth-child(2),
          .ads-v2-checkbox[data-checked="true"] + span:nth-child(2) {
            color: ${COLORS_MAP.SELECTED_ITEM} !important;
          }
        }

        [data-arg-name] {
          &[data-arg-type="Int"],
          &[data-arg-type="String"] {
            display: flex;
            align-items: center;

            > span:nth-child(2) {
              margin-left: 4px;
            }
          }

          > span:nth-child(1) {
            display: flex;
            align-items: center;
          }

          > span:nth-child(1) > span:nth-child(2) {
            color: ${COLORS_MAP.ARGUMENT} !important;
          }

          span:has(.arrow-icon[data-open="true"]) + span:nth-child(2),
          .ads-v2-checkbox[data-checked="true"] + span:nth-child(2) {
            color: ${COLORS_MAP.SELECTED_ITEM} !important;
          }
        }
      }
    }
  }
`;

const ExplorerCheckbox = styled(Checkbox)`
  && {
    top: -6px;
    padding-left: 12px;

    .ads-v2-checkbox__square {
      width: 12px;
      height: 12px;

      &::before {
        top: 4px;
        width: 7px;
        left: 2px;
      }

      &::after {
        left: 2px;
        top: 5px;
        width: 3px;
      }
    }
  }
`;

const ExplorerRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${graphiqlExplorerStyles}
`;

/**
 * Same as [QueryHeader](https://github.com/spread-ai/spread-appsmith/blob/9eac55a380e1ffe518d5d708e0ae58ca4a902cc8/app/client/src/pages/Editor/APIEditor/GraphQL/QueryEditor.tsx#L21-L26)
 * and [VariableHeader](https://github.com/spread-ai/spread-appsmith/blob/9eac55a380e1ffe518d5d708e0ae58ca4a902cc8/app/client/src/pages/Editor/APIEditor/GraphQL/VariableEditor.tsx#L27-L32)
 * 🤷
 */
const Header = styled.div`
  display: flex;
  width: 100%;
  background: var(--ads-v2-color-bg-subtle);
  padding: 8px 16px;
`;

export interface GraphQLExplorerProps {
  onEdit: (newQuery: string) => void;
  query: string;
}
export const EINGraphQLExplorer: React.FC<GraphQLExplorerProps> = ({
  onEdit,
  query,
}) => {
  const einSchema = getEinSchema();

  return (
    <ExplorerRoot>
      <Header>
        <Text color={"var(--ads-v2-color-fg)"} type={TextType.H6}>
          Explorer
        </Text>
      </Header>

      <GraphiQLExplorer
        arrowClosed={
          <span className="arrow-icon" data-open="false">
            <Icon name="arrow-right-s-line" size="sm" />
          </span>
        }
        arrowOpen={
          <span className="arrow-icon" data-open="true">
            <Icon name="arrow-down-s-line" size="sm" />
          </span>
        }
        checkboxChecked={<ExplorerCheckbox isSelected />}
        checkboxUnchecked={<ExplorerCheckbox isSelected={false} />}
        explorerIsOpen
        hideActions
        onEdit={onEdit}
        query={query}
        schema={einSchema}
        showAttribution={false}
      />
    </ExplorerRoot>
  );
};
