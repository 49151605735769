import { jsCollectionIdURL } from "@appsmith/RouteBuilder";

import history, { NavigationMethod } from "utils/history";
import { isJsFunction } from "./utils";

import type { JSCollectionData } from "@appsmith/reducers/entityReducers/jsActionsReducer";
import type { JsActionData } from "./types";

/**
 * Original `SearchItem` from "components/editorComponents/GlobalSearch/utils" is any.
 * We redefine it here to avoid any type errors.
 */
type SearchItem = JSCollectionData | JsActionData;

/**
 * It's required to run `toggleShow()` function after calling this function.
 */
export const handleJSItemClick = (item: SearchItem) => {
  const { config } = item;
  const { id, pageId } = config;

  const { collectionId, functionName } = (() => {
    const isFunction = isJsFunction(item);

    if (isFunction) {
      const { collectionId, functionName } = config as JsActionData["config"];
      return { collectionId, functionName };
    }

    // JS Collection
    return {
      collectionId: id,
      functionName: undefined,
    };
  })();

  history.push(
    jsCollectionIdURL({
      pageId,
      collectionId,
      functionName,
    }),
    { invokedBy: NavigationMethod.Omnibar },
  );
};
