/**
 * This slice is used to show/hide the entity usage info dialog.
 * This dialog is shown when the user right clicks on a widget and selects "Show usage".
 */

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { EntityTypeValue } from "entities/DataTree/dataTreeFactory";

interface EntityUsageInfo {
  show: boolean;
  entityId: string;
  entityName: string;
  entityType?: EntityTypeValue;
}

interface ExplorerReduxState {
  entityUsageInfo: EntityUsageInfo;
}

const initialState: ExplorerReduxState = {
  entityUsageInfo: {
    show: false,
    entityId: "",
    entityName: "",
  },
};

interface ShowEntityUsageInfoPayload {
  entityId: string;
  entityName: string;
  entityType?: EntityTypeValue;
}

const usageExplorerSlice = createSlice({
  name: "usageExplorer",
  initialState,
  reducers: {
    showEntityUsageInfo: (
      state,
      action: PayloadAction<ShowEntityUsageInfoPayload>,
    ) => {
      state.entityUsageInfo = {
        ...action.payload,
        show: true,
      };
    },
    hideEntityUsageInfo: (state) => {
      state.entityUsageInfo = {
        ...initialState.entityUsageInfo,
        show: false,
      };
    },
  },
});

export const { hideEntityUsageInfo, showEntityUsageInfo } =
  usageExplorerSlice.actions;
export default usageExplorerSlice.reducer;
