import { Tooltip, Button } from "design-system";
import React from "react";

import { createMessage } from "@appsmith/constants/messages";
import {
  PUBLISH_PACKAGE_LABEL,
  PUBLISH_PACKAGE_IN_LAUNCHER,
} from "../../messages";

const PackagePublish = () => {
  return (
    <Tooltip
      content={createMessage(PUBLISH_PACKAGE_IN_LAUNCHER)}
      placement="bottomRight"
    >
      <Button kind="tertiary" renderAs="a" size="md" startIcon={"rocket"}>
        {createMessage(PUBLISH_PACKAGE_LABEL)}
      </Button>
    </Tooltip>
  );
};

export default PackagePublish;
