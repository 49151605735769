import { extractVariablesFromGraphQLQuery } from "./extractVariablesFromGraphQLQuery";

const TAB_SPACE = "	";

/**
 * Generates the value of the variables object for a GraphQL query.
 * For each variable, it generates a line of code that assigns the variable to the value from input params.
 * If the query is invalid - it returns a comment with an error message.
 *
 * @param query raw GraphQL query
 * @returns value of the variables object
 */
export const generateVariablesValue = (query: string) => {
  let variablesLines: string;

  try {
    const variables = extractVariablesFromGraphQLQuery(query);

    if (variables.length === 0) {
      variablesLines = "/* No variables */";
    } else {
      variablesLines = variables
        .map((variable) => `${TAB_SPACE}${variable}: this.params.${variable}`)
        .join(",\n");
    }
  } catch {
    variablesLines =
      "/* Invalid GraphQL query. Please fix it and regenerate variables */";
  }

  const variablesValue = `{{
{
${variablesLines}
}
}}`;

  return variablesValue;
};
