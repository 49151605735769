import * as XLSX from "xlsx";
import download from "downloadjs";
import type { DataStructure, Column } from "./types";
import { isEmpty } from "lodash";
import { UNSUPPORTED_DATA_TYPE } from "./messages";
import { XLSX_FORMAT } from "./constants";

export const exportToXlsx = (
  sheetsDataOrList: DataStructure = [],
  fileName: string = "data",
) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  if (typeof sheetsDataOrList === "string") {
    throw new Error(UNSUPPORTED_DATA_TYPE);
  }

  const handleSheetData = (
    sheetName: string,
    data: Record<string, string>[] | string[] | number[],
  ) => {
    if (isEmpty(data)) {
      return;
    }

    let worksheet;

    if (typeof data[0] === "object" && !Array.isArray(data[0])) {
      // Data is an array of objects (array of records)
      worksheet = XLSX.utils.json_to_sheet(data);
    } else if (typeof data[0] === "string" || typeof data[0] === "number") {
      // Data is an array of strings or numbers, convert to single column without name
      const transformedData = data.map((item) => ({ "": item.toString() }));
      worksheet = XLSX.utils.json_to_sheet(transformedData);
    } else {
      throw new Error(UNSUPPORTED_DATA_TYPE);
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  };

  if (Array.isArray(sheetsDataOrList)) {
    // If sheetsDataOrList is an array, handle it as a single sheet
    handleSheetData("Sheet1", sheetsDataOrList);
  } else {
    // If sheetsDataOrList is an object, iterate over its keys to create sheets
    Object.keys(sheetsDataOrList).forEach((sheetName) => {
      const sheetData = sheetsDataOrList[sheetName];

      if (sheetData && sheetData.columns && sheetData.data) {
        // If the data is in the SheetData format, transform it
        const { columns, data } = sheetData;

        const transformedData = data.map((item: Record<string, string>) => {
          const transformedItem: Record<string, string> = {};
          columns.forEach((column: Column) => {
            transformedItem[column.header] = item[column.key];
          });
          return transformedItem;
        });

        handleSheetData(sheetName, transformedData);
      } else {
        // Handle other cases assuming the sheetData itself is the data array
        handleSheetData(
          sheetName,
          sheetData as unknown as
            | Record<string, string>[]
            | string[]
            | number[],
        );
      }
    });
  }

  // Create a buffer for the workbook
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a blob from the buffer
  const blob = new Blob([buffer], {
    type: XLSX_FORMAT,
  });

  // Use the download.js library to trigger the download
  download(blob, `${fileName}`, XLSX_FORMAT);
};
