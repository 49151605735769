import type { DefaultOptionType, LabelInValueType } from "rc-select/lib/Select";
import { uniqBy } from "lodash";

export const getAdvancedPasteSelectionMessage = (counts: {
  found: number;
  notFound: number;
}) => {
  if (counts.notFound === 0 && counts.found > 0) {
    return "Found and selected all pasted items.";
  } else if (counts.found === 0) {
    return "None of the pasted items were found.";
  } else {
    return `Found and selected ${counts.found} pasted item(s). ${counts.notFound} item(s) were not found.`;
  }
};

export const buildSelectionFromPastedList = (
  clipboardData: string,
  value: LabelInValueType[],
  filteredOptions: DefaultOptionType[],
) => {
  // ---------
  const newOptions = clipboardData
    .split(/[\n;,]+/)
    .map((value) => value.trim())
    .filter(Boolean);

  // Get all options
  const allOptions: LabelInValueType[] = filteredOptions.map(
    ({ label, value }) => ({
      value: value || "",
      label,
    }),
  );

  const notFoundOptions: string[] = [];
  const foundOptions: LabelInValueType[] = [];
  newOptions.forEach((val) => {
    const found = allOptions.find((element) => element.value === val);
    if (found) {
      foundOptions.push({
        ...found,
        key: found.value,
      });
    } else {
      notFoundOptions.push(val);
    }
  });

  const msg = getAdvancedPasteSelectionMessage({
    found: foundOptions.length,
    notFound: notFoundOptions.length,
  });

  // Add also existing values so the paste is additive, not replacive.
  value.forEach((val) => {
    const found = allOptions.find((element) => element.value === val.value);
    if (found) {
      foundOptions.push({
        ...found,
        key: found.value,
      });
    }
  });

  // get unique selected values amongst foundOptions and Value
  const allSelectedValues = uniqBy([...foundOptions, ...value], "value").map(
    (val) => ({
      ...val,
      key: val.value,
    }),
  );

  return {
    allSelectedValues: allSelectedValues,
    message: msg,
  };
};
