import { updateAndSaveLayout } from "actions/pageActions";
import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { CanvasWidgetsReduxState } from "reducers/entityReducers/canvasWidgetsReducer";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { getNextEntityName } from "utils/AppsmithUtils";

import { generateReactKey } from "utils/generators";
import type { WidgetProps } from "widgets/BaseWidget";
import WidgetFactory from "WidgetProvider/factory";
import { GRID_DENSITY_MIGRATION_V1 } from "WidgetProvider/constants";
import { getIsAutoLayout } from "selectors/editorSelectors";
import { getUpdateDslAfterCreatingChild } from "../WidgetAdditionSagas";
import { getWidget } from "../selectors";
import type { Step, StepsObj } from "../../widgets/StepperWidget/widget";

const WidgetTypes = WidgetFactory.widgetTypes;

interface WidgetAddStepChild {
  steps: Step[];
  widgetId: string;
}

const getChildStepData = (stepProps: WidgetProps, step: Step) => {
  const columns =
    (stepProps.rightColumn - stepProps.leftColumn) *
    stepProps.parentColumnSpace;
  const rows =
    (stepProps.bottomRow - stepProps.topRow - GRID_DENSITY_MIGRATION_V1) *
    stepProps.parentRowSpace;
  return {
    type: WidgetTypes.CANVAS_WIDGET,
    columns: columns,
    rows: rows,
    topRow: 1,
    newWidgetId: step.widgetId,
    widgetId: stepProps.widgetId,
    leftColumn: 0,
    rightColumn:
      (stepProps.rightColumn - stepProps.leftColumn) *
      stepProps.parentColumnSpace,
    bottomRow:
      (stepProps.bottomRow - stepProps.topRow) * stepProps.parentRowSpace,
    props: {
      stepId: step.id,
      stepName: step.label,
      containerStyle: "none",
      canExtend: false,
      detachFromLayout: true,
      children: [],
    },
  };
};

function* addNewStepChildSaga(
  addChildStepAction: ReduxAction<WidgetAddStepChild>,
) {
  const { widgetId } = addChildStepAction.payload;
  const stepProps: WidgetProps = yield select(getWidget, widgetId);
  let steps: StepsObj = stepProps.stepsObj;
  const stepsArray = Object.values(steps);
  const newStepWidgetId = generateReactKey();
  const newStepId = generateReactKey({ prefix: "step" });
  const newStepLabel = getNextEntityName(
    "Step ",
    stepsArray.map((step: Step) => step.label),
  );
  const newStepSubTitle = getNextEntityName(
    "sub title ",
    stepsArray.map((step: Step) => step?.subTitle || ""),
  );

  steps = {
    ...steps,
    [newStepId]: {
      id: newStepId,
      index: stepsArray.length,
      label: newStepLabel,
      subTitle: newStepSubTitle,
      widgetId: newStepWidgetId,
      isVisible: true,
    },
  };
  const newStepProps: any = getChildStepData(stepProps, {
    id: newStepId,
    label: newStepLabel,
    widgetId: newStepWidgetId,
  });
  const isAutoLayout: boolean = yield select(getIsAutoLayout);
  const updatedWidgets: CanvasWidgetsReduxState = yield call(
    getUpdateDslAfterCreatingChild,
    isAutoLayout ? { ...newStepProps, topRow: 0 } : newStepProps,
  );
  updatedWidgets[widgetId]["stepsObj"] = steps;
  yield put(updateAndSaveLayout(updatedWidgets));
}

export default function* widgetSpreadAdditionSagas() {
  yield all([
    takeEvery(ReduxActionTypes.WIDGET_ADD_NEW_STEP_CHILD, addNewStepChildSaga),
  ]);
}
