import { APPLICATIONS_URL } from "constants/routes";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "design-system";

import { createMessage } from "@appsmith/constants/messages";
import { isPermitted } from "@appsmith/utils/permissionHelpers";
import { MenuTypes } from "pages/Editor/EditorName/types";
import type { MenuItemData } from "pages/Editor/EditorName/NavigationMenuItem";
import { getCurrentPackage } from "spread/packages/selectors";
import { PACKAGE_USER_PERMISSIONS_TYPE } from "spread/packages/permissions";
import {
  BACK_TO_ALL_PACKAGES,
  RENAME_PACKAGE_LABEL,
  EXPORT_PACKAGE_LABEL,
  DELETE_PACKAGE_LABEL,
  ARE_YOU_SURE,
  DELETE_PACKAGE_ERROR,
} from "spread/packages/messages";
import { Colors } from "constants/Colors";
import { deletePackageAction } from "spread/store/slices/packages";

export interface UsePackageNavigationMenuItemsOptions {
  enableEditMode: (e: React.MouseEvent) => void;
}

export function usePackageNavigationMenuItems({
  enableEditMode,
}: UsePackageNavigationMenuItemsOptions): MenuItemData[] {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPackage = useSelector(getCurrentPackage);
  const hasDeletePackagePermission = isPermitted(
    currentPackage?.userPermissions ?? [],
    PACKAGE_USER_PERMISSIONS_TYPE.DELETE_PACKAGES,
  );

  const deletePackage = () => {
    if (currentPackage) {
      const packageId = currentPackage.id;
      dispatch(deletePackageAction({ packageId }));
      history.push(APPLICATIONS_URL);
    } else {
      toast.show(createMessage(DELETE_PACKAGE_ERROR), {
        kind: "error",
      });
    }
  };

  return [
    {
      text: createMessage(BACK_TO_ALL_PACKAGES),
      onClick: () => history.replace(APPLICATIONS_URL),
      type: MenuTypes.MENU,
      isVisible: true,
    },
    {
      type: MenuTypes.MENU_DIVIDER,
      isVisible: true,
    },
    {
      text: createMessage(RENAME_PACKAGE_LABEL),
      onClick: enableEditMode,
      type: MenuTypes.MENU,
      isVisible: true,
    },
    {
      text: createMessage(EXPORT_PACKAGE_LABEL),
      type: MenuTypes.MENU,
      isVisible: true,
    },
    hasDeletePackagePermission && {
      text: createMessage(DELETE_PACKAGE_LABEL),
      confirmText: createMessage(ARE_YOU_SURE),
      onClick: deletePackage,
      type: MenuTypes.RECONFIRM,
      isVisible: true,
      style: { color: Colors.ERROR_RED },
    },
  ].filter(Boolean) as MenuItemData[];
}
