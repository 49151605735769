import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  ERROR_MESSAGE_CREATE_PACKAGE,
  ERROR_MESSAGE_DELETE_PACKAGE,
  ERROR_MESSAGE_GET_PACKAGES_OF_WORKSPACE,
  ERROR_MESSAGE_UPDATE_PACKAGE,
  ERROR_MESSAGE_FETCH_PACKAGE,
} from "../../packages/messages";
import { createMessage } from "@appsmith/constants/messages";
import type { SpreadPackage, SpreadPackagesMap } from "../../packages/types";
import { isEmpty } from "lodash";

interface InitialState {
  packages: SpreadPackagesMap;
  currentPackage: SpreadPackage | null;
  loadingStates: {
    isFetchingPackages: boolean;
    isUpdatingPackage: boolean;
    isDeletingPackage: boolean;
    isCreatingPackage: boolean;
    isFetchingPackage: boolean;
    isUpdatingCurrentPackage: boolean;
  };
  errors: {
    getPackagesOfWorkspaceError: string | null;
    createPackageError: string | null;
    updatePackageError: string | null;
    deletePackageError: string | null;
    fetchPackageError: string | null;
    updateCurrentPackageError: string | null;
  };
}

const initialState: InitialState = {
  packages: {},
  currentPackage: null,
  loadingStates: {
    isFetchingPackages: false,
    isUpdatingPackage: false,
    isDeletingPackage: false,
    isCreatingPackage: false,
    isFetchingPackage: false,
    isUpdatingCurrentPackage: false,
  },
  errors: {
    getPackagesOfWorkspaceError: null,
    createPackageError: null,
    updatePackageError: null,
    deletePackageError: null,
    fetchPackageError: null,
    updateCurrentPackageError: null,
  },
};

const packagesSlice = createSlice({
  name: "packages",
  initialState: initialState,
  reducers: {
    // package creation
    createPackageAction: (
      state,
      action: PayloadAction<{
        name: string;
        icon: string;
        color: string;
        workspaceId: string;
      }>,
    ) => {
      state.loadingStates.isCreatingPackage = !isEmpty(action.payload);
      state.errors.createPackageError = null;
    },
    createPackageSuccessAction: (state, action) => {
      state.loadingStates.isCreatingPackage = false;
      state.packages[action.payload.id] = action.payload;
    },
    createPackageErrorAction: (state) => {
      state.loadingStates.isCreatingPackage = false;
      state.errors.createPackageError = createMessage(
        ERROR_MESSAGE_CREATE_PACKAGE,
      );
    },

    // fetching packages
    getPackagesOfWorkspaceAction: (state) => {
      state.loadingStates.isFetchingPackages = true;
      state.errors.getPackagesOfWorkspaceError = null;
    },
    getPackagesOfWorkspaceSuccessAction: (state, action) => {
      state.loadingStates.isFetchingPackages = false;
      const workspacePackages = action.payload.reduce(
        (acc: SpreadPackage, spreadPackage: SpreadPackage) => ({
          ...acc,
          [spreadPackage.id]: spreadPackage,
        }),
        {},
      );
      state.packages = workspacePackages;
    },
    getPackagesOfWorkspaceErrorAction: (state) => {
      state.loadingStates.isFetchingPackages = false;
      state.errors.getPackagesOfWorkspaceError = createMessage(
        ERROR_MESSAGE_GET_PACKAGES_OF_WORKSPACE,
      );
    },
    // package deletion
    deletePackageAction: (state, action) => {
      state.loadingStates.isDeletingPackage = !isEmpty(action.payload);
      state.errors.deletePackageError = null;
    },
    deletePackageSuccessAction: (state, action) => {
      state.loadingStates.isDeletingPackage = false;
      delete state.packages[action.payload];
    },
    deletePackageErrorAction: (state) => {
      state.loadingStates.isDeletingPackage = false;
      state.errors.deletePackageError = createMessage(
        ERROR_MESSAGE_DELETE_PACKAGE,
      );
    },
    // package update
    updatePackageAction: (state, action) => {
      state.loadingStates.isUpdatingPackage = !isEmpty(action.payload);
      state.errors.updatePackageError = null;
    },
    updatePackageSuccessAction: (state, action) => {
      state.loadingStates.isUpdatingPackage = false;
      const updatedPackage = action.payload;
      state.packages[updatedPackage.id] = updatedPackage;
    },
    updatePackageErrorAction: (state) => {
      state.loadingStates.isUpdatingPackage = false;
      state.errors.updatePackageError = createMessage(
        ERROR_MESSAGE_UPDATE_PACKAGE,
      );
    },
    // package fetch
    fetchPackageAction: (state, action) => {
      state.loadingStates.isFetchingPackage = !isEmpty(action.payload);
      state.errors.fetchPackageError = null;
    },
    fetchPackageSuccessAction: (state, action) => {
      state.loadingStates.isFetchingPackage = false;
      state.currentPackage = action.payload;
    },
    fetchPackageErrorAction: (state) => {
      state.loadingStates.isFetchingPackage = false;
      state.errors.deletePackageError = createMessage(
        ERROR_MESSAGE_FETCH_PACKAGE,
      );
    },
  },
});

export const {
  createPackageAction,
  createPackageErrorAction,
  createPackageSuccessAction,
  deletePackageAction,
  deletePackageErrorAction,
  deletePackageSuccessAction,
  fetchPackageAction,
  fetchPackageErrorAction,
  fetchPackageSuccessAction,
  getPackagesOfWorkspaceAction,
  getPackagesOfWorkspaceErrorAction,
  getPackagesOfWorkspaceSuccessAction,
  updatePackageAction,
  updatePackageErrorAction,
  updatePackageSuccessAction,
} = packagesSlice.actions;

export default packagesSlice.reducer;
