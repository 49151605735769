import type { SpreadUser } from "spread/types";
import { prefetchEinSchema } from "./einSchema";
import { initFeatureFlagsService, updateUserContext } from "./featureFlags";
import { prefetchEnabledFlows } from "./flows/enabledFlows";
import { getSystemConfig } from "./systemConfig";
import {
  initUserTracking,
  updateLoggedUserInfo as updateLoggedUserInfoForUserTracking,
} from "./userTracking";

export const initSpreadServices = async () => {
  const systemConfig = await getSystemConfig();
  if (systemConfig.unleash.enabled && systemConfig.unleash.frontendApiKey) {
    initFeatureFlagsService({ apiKey: systemConfig.unleash.frontendApiKey });
  }

  initUserTracking({
    hotjarTrackingCode: systemConfig.hotjarTrackingCode,
    heapTrackingCode: systemConfig.heapTrackingCode,
  });

  prefetchEinSchema();
  prefetchEnabledFlows();
};

export function updateLoggedUserInfo(user: SpreadUser) {
  updateLoggedUserInfoForUserTracking({
    id: user.id,
    name: user.name,
    email: user.email,
    language: user.language,
  });

  updateUserContext({
    userEmail: user.email,
  });
}
