import type { SearchItem } from "components/editorComponents/GlobalSearch/utils";

const isJsPlugin = (item: SearchItem) => {
  const pluginType = item.config.pluginType;
  return pluginType === "JS";
};

/**
 * Helper function to distinguish between JS collections and JS functions
 */
export const isJsCollection = (item: SearchItem) => {
  if (!isJsPlugin(item)) return false;

  // Only collections have actions (functions) and variables
  return !!item.config.actions && !!item.config.variables;
};

/**
 * Helper function to distinguish between JS collections and JS functions
 */
export const isJsFunction = (item: SearchItem) => {
  if (!isJsPlugin(item)) return false;

  return item.config.entityReferenceType === "JSACTION";
};
