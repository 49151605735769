import type { ButtonProps } from "design-system";
import { Button, Menu, MenuContent, MenuTrigger } from "design-system";
import React from "react";
import styled from "styled-components";

const MAIN_BUTTON_CLASSNAME = "SplitButton__mainButton";
const MENU_TRIGGER_CLASSNAME = "SplitButton__menuTrigger";

const SplitButtonRoot = styled.div`
  display: inline-flex;
  gap: 1px;

  .${MAIN_BUTTON_CLASSNAME} {
    // Appsmith code uses important for the radius...
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .${MENU_TRIGGER_CLASSNAME} {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .${MENU_TRIGGER_CLASSNAME}[aria-expanded="true"] {
    --button-color-bg: var(--ads-v2-color-bg-brand-emphasis-plus);
    --button-color-fg: var(--ads-v2-color-fg-on-brand);
    --button-color-border: var(--ads-v2-color-border-brand-emphasis);
  }
`;

export const MenuItemHelpText = styled.div`
  color: var(--ads-v2-color-fg-muted);
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`;

type ForwardedButtonProps = Omit<ButtonProps, "isIconButton">;

export interface SplitButtonProps extends ForwardedButtonProps {
  /**
   * The menu items shown when the user clicks on the menu trigger
   * of the split button.
   *
   * Items are rendered inside ADS' `MenuContent`.
   *
   * @example
   * const menuItems = (
   *   <>
   *     <MenuItem startIcon="settings-line" onClick={() => doSomething()}>
   *       MenuItem 1
   *       <MenuItemHelpText>Help text</MenuItemHelpText>
   *     </MenuItem>
   *     <MenuSeparator />
   *     <MenuSub>
   *       <MenuSubTrigger>Sub Menu</MenuSubTrigger>
   *       <MenuSubContent>
   *         <MenuItem>Sub Item 1</MenuItem>
   *       </MenuSubContent>
   *     </MenuSub>
   *   </>
   * );
   *
   * @see https://github.com/appsmithorg/design-system/tree/16fad3f636421654c9917ff9108149a42c203027/packages/design-system/src/Menu
   * @see https://www.radix-ui.com/primitives/docs/components/dropdown-menu#dropdown-menu
   */
  menuItems: React.ReactNode;
}
export const SplitButton: React.FC<SplitButtonProps> = (props) => {
  const {
    className,
    isDisabled,
    isLoading,
    kind,
    menuItems,
    size,
    ...forwardedMainButtonProps
  } = props;
  const sharedProps: ButtonProps = {
    kind,
    size,
  };

  return (
    <SplitButtonRoot className={className}>
      <Button
        className={MAIN_BUTTON_CLASSNAME}
        isDisabled={isDisabled}
        isLoading={isLoading}
        {...sharedProps}
        {...forwardedMainButtonProps}
      />
      <Menu>
        <MenuTrigger>
          <Button
            {...sharedProps}
            aria-label="More options"
            className={MENU_TRIGGER_CLASSNAME}
            isDisabled={isDisabled || isLoading}
            isIconButton
            startIcon="down-arrow"
          />
        </MenuTrigger>
        <MenuContent loop>{menuItems}</MenuContent>
      </Menu>
    </SplitButtonRoot>
  );
};
