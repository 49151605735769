import {
  type ReduxAction,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import type { CanvasWidgetsReduxState } from "../../reducers/entityReducers/canvasWidgetsReducer";
import { all, call, select, takeEvery } from "redux-saga/effects";
import { getWidgets, getWidgetsMeta } from "../selectors";
import { orderBy } from "lodash";
import { LayoutSystemTypes } from "../../layoutSystems/types";
import { getLayoutSystemType } from "../../selectors/layoutSystemSelectors";
import {
  getCanvasWidth,
  getIsAutoLayoutMobileBreakPoint,
} from "../../selectors/editorSelectors";
import { updateFlexLayersOnDelete } from "../../layoutSystems/autolayout/utils/AutoLayoutUtils";
import { updateAnvilParentPostWidgetDeletion } from "../../layoutSystems/anvil/utils/layouts/update/deletionUtils";
import { updateAndSaveAnvilLayout } from "../../layoutSystems/anvil/utils/anvilChecksUtils";
import type { WidgetProps } from "../../widgets/BaseWidget";

import {
  getUpdatedDslAfterDeletingWidget,
  postDelete,
} from "../WidgetDeletionSagas";

interface WidgetDeleteStepChild {
  id: string;
  index: number;
  isVisible: boolean;
  label: string;
  widgetId: string;
}

type UpdatedDSLPostDelete =
  | {
      finalWidgets: CanvasWidgetsReduxState;
      otherWidgetsToDelete: (WidgetProps & {
        children?: string[] | undefined;
      })[];
      widgetName: string;
    }
  | undefined;

export function* deleteStepChildSaga(
  deleteChildStepAction: ReduxAction<WidgetDeleteStepChild>,
) {
  const { index, label, widgetId } = deleteChildStepAction.payload;
  const allWidgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const stepWidget = allWidgets[widgetId];
  if (stepWidget && stepWidget.parentId) {
    const stepParentWidget = allWidgets[stepWidget.parentId];
    const stepsArray: any = orderBy(
      Object.values(stepParentWidget.stepsObj),
      "index",
      "asc",
    );
    if (stepsArray && stepsArray.length === 1) return;
    const updatedArray = stepsArray.filter((eachItem: any, i: number) => {
      return i !== index;
    });
    const updatedObj = updatedArray.reduce(
      (obj: any, each: any, index: number) => {
        obj[each.id] = {
          ...each,
          index,
        };
        return obj;
      },
      {},
    );
    const widgetType: string = allWidgets[widgetId].type;
    const updatedDslObj: UpdatedDSLPostDelete = yield call(
      getUpdatedDslAfterDeletingWidget,
      widgetId,
      stepWidget.parentId,
    );
    if (updatedDslObj) {
      const { finalWidgets, otherWidgetsToDelete } = updatedDslObj;
      const parentUpdatedWidgets = {
        ...finalWidgets,
        [stepParentWidget.widgetId]: {
          ...finalWidgets[stepParentWidget.widgetId],
          stepsObj: updatedObj,
        },
      };
      const layoutSystemType: LayoutSystemTypes =
        yield select(getLayoutSystemType);
      let finalData: CanvasWidgetsReduxState = parentUpdatedWidgets;
      if (layoutSystemType === LayoutSystemTypes.AUTO) {
        // Update flex layers of a canvas upon deletion of a widget.
        const isMobile: boolean = yield select(getIsAutoLayoutMobileBreakPoint);
        const mainCanvasWidth: number = yield select(getCanvasWidth);
        const metaProps: Record<string, any> = yield select(getWidgetsMeta);
        finalData = yield call(
          updateFlexLayersOnDelete,
          parentUpdatedWidgets,
          widgetId,
          stepWidget.parentId,
          isMobile,
          mainCanvasWidth,
          metaProps,
        );
      } else if (layoutSystemType === LayoutSystemTypes.ANVIL) {
        finalData = updateAnvilParentPostWidgetDeletion(
          finalData,
          stepWidget.parentId,
          widgetId,
          widgetType,
        );
      }
      yield call(updateAndSaveAnvilLayout, finalData);
      yield call(postDelete, widgetId, label, otherWidgetsToDelete);
    }
  }
}

export default function* widgetSpreadDeletionSagas() {
  yield all([
    takeEvery(ReduxActionTypes.WIDGET_DELETE_STEP_CHILD, deleteStepChildSaga),
  ]);
}
