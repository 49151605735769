/* Heap & Hotjar tracking code */

import { getConfigValueFromFlag } from "spread/featureFlags";
import type { SpreadUser } from "spread/types";
import userflow from "userflow.js";

declare global {
  interface Window {
    heap: HeapAnalytics | undefined;
    hj: Hotjar | undefined;
  }
}

export function initUserTracking(config: {
  hotjarTrackingCode: number | null;
  heapTrackingCode: number | null;
}) {
  if (config.heapTrackingCode) {
    addHeapScriptTag(config.heapTrackingCode);
  }

  if (config.hotjarTrackingCode) {
    addHotjarScriptTag(config.hotjarTrackingCode);
  }

  void initUserflow();
}

export function updateLoggedUserInfo(user: SpreadUser) {
  window.heap?.identify(user.id);
  window.heap?.addUserProperties({
    name: user.name,
    email: user.email,
    language: user.language,
  });

  window.hj?.("identify", user.id, {
    name: user.name,
    email: user.email,
    language: user.language,
  });

  void userflow
    .identify(user.id, {
      name: user.name,
      email: user.email,
    })
    .catch(() => {
      // Userflow throws if it was not initialised, which is the case when the token is not set.
      // Just ignoring it.
    });
}

function addScriptTag(
  scriptContent: string,
  onScriptLoad?: HTMLScriptElement["onload"],
) {
  const scriptTag = document.createElement("script");
  scriptTag.innerHTML = scriptContent;
  if (onScriptLoad) {
    scriptTag.onload = onScriptLoad;
  }
  document.head.appendChild(scriptTag);
}

function addHeapScriptTag(userTrackingId: number) {
  const scriptContent = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${userTrackingId}");`;
  addScriptTag(scriptContent);
}

function addHotjarScriptTag(userTrackingId?: number | null) {
  if (!userTrackingId) {
    return;
  }
  const scriptContent = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${userTrackingId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

  addScriptTag(scriptContent);
}

async function initUserflow() {
  const userflowToken = await getConfigValueFromFlag(
    "config.UserflowToken",
    "token",
  );
  if (userflowToken) {
    userflow.init(userflowToken);
  }
}

interface HeapAnalytics {
  /**
   * The `identify` API lets you attach a unique identifier to a given user. This allows you to track and maintain user histories across multiple sessions and devices under a single profile.
   *
   * **WHEN AND WHERE TO CALL IDENTIFY**
   * - The API can be called at any time and as many times as needed.
   * - Call the API on all post-login or post-signup pages.
   * - For mobile apps, call the API on every launch.
   *
   * **WHEN AND WHERE TO CALL IDENTIFY**
   * - The API can be called at any time and as many times as needed.
   * - Call the API on all post-login or post-signup pages.
   * - For mobile apps, call the API on every launch.
   *
   * @see https://heapanalytics.com/app/env/1128594942/manage/user-identities
   * @see https://developers.heap.io/docs/using-identify
   */
  identify(userUniqueId: string): void;

  /**
   * The `addUserProperties` API lets you attach custom properties to users. This enables you to segment users by properties such as payment plan or marketing segment. When setting properties, it's OK to call the addUserProperties API on any page.
   *
   * @see https://heapanalytics.com/app/env/1128594942/manage/user-identities
   * @see https://developers.heap.io/docs/using-identify#user-properties
   */
  addUserProperties(properties: Record<string, any>): void;
}

interface Hotjar {
  /**
   * Hotjar’s Identify API allows you to pass data about your users to Hotjar using Javascript, saving them as User Attributes. These User Attributes can be used by Hotjar to enable advanced filtering and segmentation, giving you quality insights faster than before. You can send up to 100 user attributes on any site, with any number of users associated with those attributes.
   *
   * **Plan availability**
   * - Available for Recordings and Heatmaps with "Observe Business" or 'Observe Scale'.
   * - Available for Feedback and Surveys with "Ask Business" or "Ask Scale".
   *
   * @see https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference
   */
  (
    operation: "identify",
    userId: string,
    attributes?: Record<string, string>,
  ): void;
  (operation: string, ...args: any[]): void;
}
