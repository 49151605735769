import { combineReducers } from "redux";

import usageExplorerReducer from "./slices/usageExplorer";
import packagesReducer from "./slices/packages";

const spreadReducers = combineReducers({
  usageExplorer: usageExplorerReducer,
  packages: packagesReducer,
});

export type SpreadAppState = ReturnType<typeof spreadReducers>;

export default spreadReducers;
