import Api from "../../api/Api";
import type { ApiResponse } from "../../api/ApiResponses";
import type { AxiosPromise } from "axios";
import type { AppColorCode } from "../../constants/DefaultTheme";
import type { IconNames } from "design-system";
import type { SpreadPackage } from "./types";

interface Page {
  id: string;
  isDefault: boolean;
  default: boolean;
}

interface ApplicationDetail {
  appPositioning: {
    type: string;
  };
}

export interface CreatePackageResponseData {
  id: string;
  modifiedBy: string;
  userPermissions: string[];
  name: string;
  workspaceId: string;
  isPublic: boolean;
  pages: Page[];
  appIsExample: boolean;
  unreadCommentThreads: number;
  color: AppColorCode;
  icon: string;
  slug: string;
  unpublishedCustomJSLibs: any[];
  publishedCustomJSLibs: any[];
  evaluationVersion: number;
  applicationVersion: number;
  collapseInvisibleWidgets: boolean;
  isManualUpdate: boolean;
  unpublishedThemeId: string;
  publishedThemeId: string;
  applicationDetail: ApplicationDetail;
  new: boolean;
  modifiedAt: string;
}

export type CreatePackageResponse = ApiResponse<CreatePackageResponseData>;
export type GetAllPackagesOfWorkspaceResponse = ApiResponse<SpreadPackage[]>;
export type DeletePackageResponse = ApiResponse<SpreadPackage>;
export type UpdatePackageResponse = ApiResponse<SpreadPackage>;
export type FetchPackageResponse = ApiResponse<SpreadPackage>;

export interface CreatePackageRequest {
  name: string;
  color: AppColorCode;
  icon: IconNames;
  workspaceId: string;
}

export interface UpdatePackagePayload {
  name: string;
  color: AppColorCode;
  icon: IconNames;
}
export interface UpdatePackageRequest {
  data: UpdatePackagePayload;
  packageId: string;
}

const baseURL = "v1/packages";

const createPackagePath = (workspaceId: string) =>
  `?workspaceId=${workspaceId}`;

const getAllPackagesOfWorkspacesPath = createPackagePath;

const updatePackagePath = (packageId: string) => `/${packageId}`;

const deletePackagePath = (workspaceId: string) => `/${workspaceId}`;

const fetchPackagePath = (packageId: string) => `/${packageId}`;

export const createPackage = async (
  request: CreatePackageRequest,
): Promise<AxiosPromise<CreatePackageResponse>> => {
  return Api.post(baseURL + createPackagePath(request.workspaceId), {
    name: request.name,
    color: request.color,
    icon: request.icon,
  });
};

export const getAllPackagesOfWorkspace = async (
  workspaceId: string,
): Promise<AxiosPromise<GetAllPackagesOfWorkspaceResponse>> => {
  return Api.get(baseURL + getAllPackagesOfWorkspacesPath(workspaceId));
};

export const deletePackage = async (
  packageId: string,
): Promise<AxiosPromise<DeletePackageResponse>> => {
  return Api.delete(baseURL + deletePackagePath(packageId));
};

export const updatePackage = async (
  request: UpdatePackageRequest,
): Promise<AxiosPromise<UpdatePackageResponse>> => {
  const { packageId, ...rest } = request;
  return Api.put(baseURL + updatePackagePath(packageId), rest.data);
};

export const fetchPackage = async (
  packageId: string,
): Promise<AxiosPromise<FetchPackageResponse>> => {
  return Api.get(baseURL + fetchPackagePath(packageId));
};
