import { gql, GraphQLClient } from "graphql-request";
import { EIN_PATH } from "./urls";

const client = new GraphQLClient(EIN_PATH, {
  credentials: "include",
  mode: "cors",
  // it uses XMLHttpRequest by default, but it's not available in service workers
  fetch: "fetch" in globalThis ? globalThis.fetch : undefined,
});

export async function callEin<R>(
  query: string,
  variables?: Record<string, unknown>,
) {
  return client.request<R>(query, variables);
}

export { gql };
