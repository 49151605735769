import { getEinSchema } from "spread";

import type CodeMirror from "codemirror";
import type { GraphQLSchema } from "graphql";
import { getAutocompleteSuggestions, Position } from "graphql-language-service";

import {
  EditorModes,
  type HintHelper,
} from "components/editorComponents/CodeEditor/EditorConfig";
import { getCodeMirrorNamespaceFromEditor } from "utils/getCodeMirrorNamespace";

interface Hints {
  list: Hint[];
  from: CodeMirror.Position;
  to: CodeMirror.Position;
}

interface Hint {
  text: string;
  insertText?: string;
  type?: string;
  render: (el: HTMLElement, data: Hints, cur: Hint) => void;
  hint: (cm: CodeMirror.Editor, data: Hints, cur: Hint) => void;
}

const getGraphqlHints = (
  editor: CodeMirror.Editor,
  schema: GraphQLSchema,
): Hints => {
  const cursor = editor.getCursor();
  const token = editor.getTokenAt(cursor);

  const tokenStart =
    token.type !== null && /"|\w/.test(token.string[0])
      ? token.start
      : token.end;

  const position = new Position(cursor.line, token.end);

  const suggestions = getAutocompleteSuggestions(
    schema,
    editor.getValue(),
    position,
    token,
    undefined,
    {
      fillLeafsOnComplete: true,
    },
  );

  const list = suggestions.map<Hint>((item) => {
    const insertText = (() => {
      if (item.insertText) {
        return item.insertText;
      }
      // TODO: based on `item.type` we can make `insertText` more sophisticated
      // For example, if type is array of objects, we can insert `{}` with cursor inside
      // which is not implemented by `fillLeafsOnComplete` option in `getAutocompleteSuggestions`

      return undefined;
    })();

    // Note: `item` contains `documentation`, `isDeprecated`, `deprecationReason` fields
    // We can use them to provide more information in the hint

    return {
      text: item.label,
      insertText,
      type: item.type?.toString(),
      hint: handlePick,
      render: handleHintRender,
    };
  });

  const CodeMirror = getCodeMirrorNamespaceFromEditor(editor);

  const from = CodeMirror.Pos(cursor.line, tokenStart);
  const to = CodeMirror.Pos(cursor.line, token.end);

  return {
    list,
    from,
    to,
  };
};

function isEinQuery(editor: CodeMirror.Editor) {
  const mode = editor.getOption("mode");
  return mode === EditorModes.GRAPHQL_EIN;
}

/**
 * Replace the text in the editor from the `from` position to the `to` position with the `replaceText`.
 * The indent of the line where the replacement starts is preserved and added to each line of the `replaceText`.
 */
function replaceRangeWithPreservingIndents(
  editor: CodeMirror.Editor,
  replaceText: string,
  from: CodeMirror.Position,
  to: CodeMirror.Position,
) {
  // Get the text of the line where the replacement starts
  const lineText: string = editor.getLine(from.line);

  // Get the indent of the line
  const indent = lineText.match(/^\s*/)?.[0] || "";

  // Divide the text to paste into lines
  const lines: string[] = replaceText.split("\n");

  // Add the indent to each line
  const indentedLines: string[] = lines.map((line, index) => {
    // Add indent to all lines except the first, because the first line already has an indent
    return (index === 0 ? "" : indent) + line;
  });

  // Join the lines into a single text with line breaks
  const finalText: string = indentedLines.join("\n");

  // Replace the text in the editor
  editor.replaceRange(finalText, from, to);
}

/**
 * Handle the selection of a hint in the autocomplete list.
 * If the hint has `insertText`, replace the text in the editor from the `from` position to the `to` position with the `insertText`.
 * If the hint does not have `insertText`, replace the text in the editor from the `from` position to the `to` position with the `text`.
 * If the `insertText` contains `$1`, replace `$1` with the cursor position.
 */
function handlePick(editor: CodeMirror.Editor, data: Hints, selected: Hint) {
  if (selected.insertText) {
    // we should analyze insertText and replace $1 with cursor position
    const insertText = selected.insertText;
    const indexOfCursorTargetInInsertText = insertText.indexOf("$1");
    const insertTextAfterCursor = insertText.slice(
      indexOfCursorTargetInInsertText + 2,
    );
    const linebreaksAfterCursorAmount =
      insertTextAfterCursor.split("\n").length - 1;

    const finalInsertText = insertText.replace("$1", "");

    replaceRangeWithPreservingIndents(
      editor,
      finalInsertText,
      data.from,
      data.to,
    );

    const cursor = editor.getCursor();

    const targetLineIndex = cursor.line - linebreaksAfterCursorAmount;
    const targetLine = editor.getLine(targetLineIndex);
    const indexOfLastCharacterOfTargetLine = targetLine.length;

    const cursorPosition = {
      line: targetLineIndex,
      ch: indexOfLastCharacterOfTargetLine,
    };
    // set cursor position to the end of the inserted text
    editor.setCursor(cursorPosition);

    return;
  }

  // if insertText is not provided, just insert the text
  editor.replaceRange(selected.text, data.from, data.to);
}

/**
 * Render the hint in the autocomplete list.
 * If the hint has `type`, show the `type` in a gray color.
 * If the hint does not have `type`, show the `text` as is.
 */
function handleHintRender(el: HTMLElement, data: Hints, cur: Hint) {
  // If type is provided, we can show it in a different color
  if (cur.type) {
    const typeInfo = `<span style="color: #999;">${cur.type}</span>`;
    el.innerHTML = `${cur.text} ${typeInfo}`;
    return;
  }

  // default
  el.innerHTML = cur.text;
}

export const einQueryHintHelper: HintHelper = () => {
  return {
    showHint: (editor) => {
      if (!isEinQuery(editor)) return false;

      const schema = getEinSchema();
      if (!schema) return false;

      const hints = getGraphqlHints(editor, schema);
      if (!hints) return false;

      editor.showHint({
        hint: () => hints,
        completeSingle: false,
      });

      return true; // return true to indicate that the hint was shown
    },
    fireOnFocus: true,
  };
};
